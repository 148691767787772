.App {
    text-align: center;
}

input {
    border-radius: 0 !important;
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
    padding: 0 !important;
    outline: none !important;
    background: transparent;
}

a {
    color: #E64C4C;
}

a:hover {
    color: #E64C4C;
}

.btn-primary {
    background-color: #E64C4C !important;
    border-color: #E64C4C !important;
    border-width: 2px;
    border-radius: 10px;
    opacity: 1 !important;
}

.btn-primary:hover {
    background-color: #fff !important;
    border-color: #E64C4C !important;
    color: #E64C4C !important;
    opacity: 1 !important;
}

.btn-primary:disabled {
    background-color: #E64C4C !important;
    border-color: #E64C4C !important;
    opacity: 0.65 !important;
    pointer-events: none;
}

.btn-primary:disabled:hover {
    background-color: #E64C4C !important;
    border-color: #E64C4C !important;
    opacity: 0.65 !important;
}

.gs-text {
    background: transparent !important;
    border: none !important;
    padding: 0 !important;
    color: #3c3c3c;
}

.gs-link {
    color: #E64C4C;
}

.gs-link:hover {
    text-decoration: underline;
}

body {
    color: #3C3C3C;
}

body.gs {
    overflow-x: hidden;
}

body.modal-open {
    overflow: hidden !important;
    padding: 0 !important;
}

.send-to-back {
    z-index: -1000;
}

.modal-backdrop {
    z-index: 10005;
}

.modal {
    z-index: 10010;
}

* {
    font-family: 'Poppins', sans-serif;
}

.no-underline:hover {
    border-bottom: none !important;
}

.hover-underline:hover {
    text-decoration: underline;
}

p.gs a {
    color: #3C3C3C;
}

img.theme-icon {
    padding-top: 5rem;
}

.gs-color {
    color: #3C3C3C;
}

.gs-navbar-logo img {
    height: 6rem;
}

.gs-navbar {
    z-index: 10001;
    top: 0;
    padding: 0;
    background-color: transparent;
}

.gs-block {
    padding-top: 10rem;
    padding-bottom: 5rem;
}

.gs-logo {
    white-space: nowrap;
    height: 3.5rem;
    width: 15.75rem;
}

.gs-logo img {
    height: 100%
}

.bg-red {
    background-color: #DF7260 !important;
    color: #fff;
}

.bg-pink {
    background-color: #FFD4C4 !important;
}

.bg-yellow {
    background-color: #F1BC00 !important;
}

.bg-light-yellow {
    background-color: #FEE19B !important;
}

.bg-light-blue {
    background: #F2F9FA;
}

.bg-dark-blue {
    background: #D4EEEF;
}

.bg-blue {
    background-color: #69CDCD !important;
}

.bg-grey {
    background-color: #F7F8F9 !important;
}

.text-light-blue {
    color: #d7ecfdff !important;
}

.text-blue {
    color: #69CDCD !important;
}

.text-dark-blue {
    color: #227AD3 !important;
}

.text-red {
    color: #E64C4C !important;
}

.text-aqua {
    color: #9AD6D6 !important;
}

.text-pink {
    color: #EC9783 !important;
}

.text-yellow{
    color: #FECB48 !important;
}

.text-black {
    color: #3C3C3C !important;
}

.gs-content {
    min-height: 100vh;
}

.gs-social-media a {
    font-size: 3rem;
    color: #3c3c3c;
    padding: 1rem;
}

.gs-social-media a:hover {
    font-size: 3rem;
    color: #E64C4C;
    padding: 1rem;
}

.gs-form-container {
    max-width: 30rem;
    padding-top: 7rem;
}

.gs-login-image {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-height: 80%;
    padding: 0 5rem 0 5rem;
}

.gs-password-image {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-height: 80%;
    margin: 0rem 8rem 0rem -2rem;
}

.nav-pills .nav-link {
    border-radius: 0;
    color: #3D3D3D;
    padding: 1rem 0rem 1rem 2.25rem;
}

.nav-pills .nav-link {
    border-radius: 0;
    color: #3D3D3D;
    padding: 1rem 0rem 1rem 2.25rem;
}

.nav-pills .nav-link.disabled {
    border-radius: 0;
    color: #ABAAAA;
    padding: 1rem 0rem 1rem 2.25rem;
}

.nav-pills .nav-link:hover {
    color: #9AD6D6;
}

.nav-pills .nav-link.active {
    background-color: #D4EEEF;
    border-left: 0.75rem solid #9AD6D6;
    padding-left: 1.5rem;
    color: #3D3D3D;
    box-shadow: 0px 4px 4px rgba(212, 238, 239, 0.37);
}

.gs-dashboard-nav {
    position: fixed;
    height: 100vh;
    width: 18.5rem;
}

.gs-dashboard-content {
    margin-left: 18.5rem;
    width: calc(100% - 18.5rem);
}

.gs-home-container, .gs-account-container {
    padding-top: 10rem;
    max-width: 30rem;
}

.gs-applications-container, .gs-application-container {
    padding: 3rem;
}

.gs-schedule-container, .gs-workshop-container {
}

.gs-logout-button {
    position:absolute;
    bottom: 0;
    border-radius: 0;
    padding: 1rem 0rem 2.25rem 2.25rem;
}

.gs-event-block {
    /* background-color: #D4EEEF; */
    padding: 0.5rem;
}

.fc .fc-daygrid-day.fc-day-today {
    background-color: transparent !important;
}

.fc-daygrid-dot-event {
    display: block;
}

.fc td, .fc table {
    border-top: none !important;
    border-bottom: none !important;
}

.fc-daygrid td, .fc-daygrid th, .fc-daygrid table {
    border-style: none !important;
}

.fc-dayGrid3Day-view th, .fc-dayGrid3Day-view .fc-daygrid-day {
    border-top: none !important;
    border-bottom: none !important;
    border-left: 2px solid #3C3C3C !important;
    border-right: 2px solid #3C3C3C !important;
}

.fc-dayGrid-button.fc-button-primary, .fc-dayGrid3Day-button.fc-button-primary {
    color: #E64C4C !important;
    border-color: #FFCFCE !important;
    background: #FFCFCE !important;
    width: 6rem;
    border-width: 2px !important;
    border-radius: 10px !important;
}

.fc-dayGrid-button.fc-button-primary:hover, .fc-dayGrid3Day-button.fc-button-primary:hover {
    background-color: #fff !important;
    border-color: #E64C4C !important;
    color: #E64C4C !important;
    opacity: 1 !important;
}

.fc-button-primary:not(:disabled):active, .fc-button-primary:not(:disabled).fc-button-active {
    background-color: #E64C4C !important;
    color: #fff !important;
    border-color: #E64C4C !important;
}

.fc-today-button.fc-button.fc-button-primary, .fc-prev-button.fc-button.fc-button-primary, .fc-next-button.fc-button.fc-button-primary {
    background-color: transparent !important;
    border: none !important;
    color: #3D3D3D !important;
    font-size: 1.5rem;
    opacity: 1 !important;
}

.fc-today-button.fc-button.fc-button-primary:hover, .fc-prev-button.fc-button.fc-button-primary:hover, .fc-next-button.fc-button.fc-button-primary:hover {
    color: #E64C4C !important;
}

.fc-day-today {
    color: #E64C4C !important;
}

.nav-link {
    color: #3C3C3C !important;
}

.nav-link.active {
    color: #E64C4C !important;
}

.navbar-toggler {
    background-color: #fff !important;
    border: none;
}

@media (max-width: 768px) {
    .gs-dashboard-content {
        margin-left: auto;
        margin-right: auto;
        padding-top: 0;
        width: auto;
    }
}
